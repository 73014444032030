const ThankYou = () => {
  return (
    <>
      <p>Hope you enjoyed and learned more about SVGs.</p>
      <div className="animateIn">
        <p>Questions, comments?</p>
      </div>
    </>
  );
};

export default ThankYou;
